import React from "react";

const Twitter = () => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: "1.25rem" }}
    >
      <g clipPath="url(#clip0124312412)">
        <path
          d="M6.98163 17.9263C15.2715 17.9263 19.8046 11.0585 19.8046 5.10329C19.8046 4.90823 19.8007 4.71404 19.7919 4.52073C20.6719 3.88456 21.4368 3.09067 22.04 2.18695C21.2325 2.54589 20.3635 2.78753 19.4519 2.89649C20.3824 2.33853 21.0968 1.45589 21.4337 0.403671C20.563 0.919895 19.5986 1.29509 18.5719 1.49763C17.7494 0.621585 16.5786 0.0737305 15.2821 0.0737305C12.7932 0.0737305 10.7749 2.09206 10.7749 4.58004C10.7749 4.93371 10.8144 5.27771 10.8918 5.60765C7.14596 5.41918 3.82455 3.62579 1.60193 0.898811C1.21487 1.56485 0.991688 2.33853 0.991688 3.16404C0.991688 4.72766 1.78733 6.10806 2.99727 6.91557C2.25786 6.89272 1.56327 6.68975 0.9561 6.3519C0.955437 6.37081 0.955439 6.38924 0.955439 6.40945C0.955439 8.59209 2.50894 10.4145 4.5712 10.8274C4.19249 10.9307 3.79401 10.9861 3.38279 10.9861C3.09283 10.9861 2.81033 10.9575 2.53574 10.9048C3.10952 12.6955 4.7733 13.9986 6.74594 14.0351C5.20341 15.2441 3.26021 15.9642 1.14831 15.9642C0.784973 15.9642 0.426032 15.9435 0.0732422 15.9018C2.06784 17.1803 4.43632 17.9263 6.98186 17.9263"
          fill="#979BA7"
        />
      </g>
      <defs>
        <clipPath id="clip0124312412">
          <rect width="22.1142" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
