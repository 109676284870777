import React from "react";

const LinkedIn = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: "1.25rem" }}
    >
      <path
        d="M0 1.28941C0 0.57748 0.591764 0 1.32178 0H16.5647C17.2947 0 17.8865 0.57748 17.8865 1.28941V16.7106C17.8865 17.4228 17.2947 18 16.5647 18H1.32178C0.591764 18 0 17.4228 0 16.7106V1.28941Z"
        fill="#979BA7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.42185 15.0681V6.94011H2.72025V15.0681H5.42185ZM4.07104 5.83037C5.01314 5.83037 5.59952 5.20622 5.59952 4.42623C5.58196 3.62868 5.01314 3.02186 4.08891 3.02186C3.16477 3.02186 2.56055 3.62868 2.56055 4.42623C2.56055 5.20622 3.1468 5.83037 4.05343 5.83037H4.07099H4.07104Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91699 15.0681H9.61857V10.5291C9.61857 10.2862 9.63612 10.0435 9.70746 9.86984C9.90276 9.38447 10.3473 8.8818 11.0936 8.8818C12.0712 8.8818 12.4622 9.62716 12.4622 10.7198V15.0681H15.1636V10.4076C15.1636 7.91107 13.8308 6.74938 12.0533 6.74938C10.5959 6.74938 9.95608 7.56401 9.6006 8.11888H9.61864V6.94011H6.91707C6.95252 7.70278 6.91707 15.0681 6.91707 15.0681H6.91699Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedIn;
