import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'

import '../../styles/fonts.css'
import { theme } from '../../styles/theme'

import Navigation from './Navigation'
import Footer from './Footer'

const Template = (props) => {
  const { children, location } = props

  return (
    <ChakraProvider theme={theme}>
      <Navigation location={location} />
      {children}
      <Footer />
    </ChakraProvider>
  )
}

export default Template
