import React from "react";
import { Heading } from "@chakra-ui/react";

const SectionHeading = (props) => {
  return (
    <Heading
      as="h2"
      fontFamily="Montserrat, sans-serif"
      fontSize={["2xl", "3xl", "4xl"]}
      fontWeight={[600, 600, 700]}
      lineHeight="shorter"
      textColor="brand.100"
      maxW="2xl"
      w="full"
      pb={[2, 3]}
      {...props}
    >
      {props.children}
    </Heading>
  );
};

export default SectionHeading;
