import React from "react";
import { Center, Flex, Text, Link, Container } from "@chakra-ui/react";
import { Link as ReachLink } from "gatsby";
import Twitter from "./Twitter";
import LinkedIn from "./LinkedIn";

const Footer = () => {
  return (
    <Container
      maxW="full"
      centerContent
      py={[6, 9]}
      bgColor="brand.600"
      textColor="brand.300"
      fontSize={["sm", "md"]}
      fontWeight="500"
    >
      <Flex maxW="6xl" w="full" justifyContent="space-between" flexWrap="wrap">
        <Flex
          flex={["1 0 100%", "0 0 auto"]}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Text>&copy; {new Date().getFullYear()} DTC Ventures</Text>
        </Flex>
        <Center>
          <Link as={ReachLink} to="/imprint">
            Imprint
          </Link>
          <Link as={ReachLink} to="/privacy" ml={[5, 10]}>
            Privacy Policy
          </Link>
        </Center>
        <Center>
          {/* <Link href="https://twitter.com" isExternal h="1.25rem" w="auto">
            <Twitter />
          </Link>
          <Link
            href="https://linkedin.com"
            isExternal
            h="1.25rem"
            w="auto"
            ml={[3, 4]}
          >
            <LinkedIn />
          </Link> */}
        </Center>
      </Flex>
    </Container>
  );
};

export default Footer;
