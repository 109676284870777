import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import favIcon from "../../../static/favicon.png";

const Head = ({ data }) => {
  return (
    <StaticQuery
      query={query}
      render={(siteMeta) => (
        <Helmet
          title={data.pageTitle}
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta
            name="description"
            content={data.metaDescription.metaDescription}
          />
          <meta property="og:title" content={data.pageTitle} />
          <meta
            property="og:description"
            content={data.metaDescription.metaDescription}
          />
          <meta
            property="og:image"
            content={`https:${data.metaImage.file.url}`}
          />
          <meta
            property="og:type"
            content={siteMeta.allSite.edges[0].node.siteMetadata.type}
          />
          <meta
            property="og:url"
            content={siteMeta.allSite.edges[0].node.siteMetadata.url}
          />
          <link rel="icon" type="image/png" href={favIcon} />
        </Helmet>
      )}
    />
  );
};

export const query = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            title
            type
            url
          }
        }
      }
    }
  }
`;

export default Head;
