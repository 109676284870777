import React from 'react';

const Logo = ({fill}) => {
  return ( 
    <svg width="182" height="23" viewBox="0 0 182 23" fill="none" xmlns="http://www.w3.org/2000/svg" style={{maxWidth: "100%", height: "auto"}}>
      <path d="M8.44 1C11.3 1 13.67 2.02 15.55 4.06C17.45 6.08 18.4 8.56 18.4 11.5C18.4 14.44 17.45 16.93 15.55 18.97C13.67 20.99 11.3 22 8.44 22H0.25V1H8.44ZM8.44 19.36C10.58 19.36 12.33 18.61 13.69 17.11C15.05 15.61 15.73 13.74 15.73 11.5C15.73 9.26 15.05 7.39 13.69 5.89C12.33 4.39 10.58 3.64 8.44 3.64H3.04V19.36H8.44Z" fill={fill}/>
      <path d="M34.022 1V3.64H27.812V22H25.052V3.64H18.872V1H34.022Z" fill={fill}/>
      <path d="M45.452 22.39C42.272 22.39 39.642 21.34 37.562 19.24C35.502 17.14 34.472 14.56 34.472 11.5C34.472 8.44 35.502 5.86 37.562 3.76C39.642 1.66 42.272 0.610001 45.452 0.610001C47.372 0.610001 49.132 1.07 50.732 1.99C52.352 2.91 53.612 4.15 54.512 5.71L52.082 7.12C51.482 5.96 50.582 5.04 49.382 4.36C48.202 3.66 46.892 3.31 45.452 3.31C43.032 3.31 41.052 4.09 39.512 5.65C37.992 7.21 37.232 9.16 37.232 11.5C37.232 13.82 37.992 15.76 39.512 17.32C41.052 18.88 43.032 19.66 45.452 19.66C46.892 19.66 48.202 19.32 49.382 18.64C50.582 17.94 51.482 17.02 52.082 15.88L54.512 17.26C53.632 18.82 52.382 20.07 50.762 21.01C49.142 21.93 47.372 22.39 45.452 22.39Z" fill={fill}/>
      <path d="M70.7498 22L63.2498 1H66.2498L72.3398 18.52L78.3998 1H81.4298L73.8998 22H70.7498Z" fill={fill}/>
      <path d="M83.6655 15.7C83.9255 17.04 84.5355 18.08 85.4955 18.82C86.4755 19.56 87.6755 19.93 89.0955 19.93C91.0755 19.93 92.5155 19.2 93.4155 17.74L95.6355 19C94.1755 21.26 91.9755 22.39 89.0355 22.39C86.6555 22.39 84.7155 21.65 83.2155 20.17C81.7355 18.67 80.9955 16.78 80.9955 14.5C80.9955 12.24 81.7255 10.36 83.1855 8.86C84.6455 7.36 86.5355 6.61 88.8555 6.61C91.0555 6.61 92.8455 7.39 94.2255 8.95C95.6255 10.49 96.3255 12.35 96.3255 14.53C96.3255 14.91 96.2955 15.3 96.2355 15.7H83.6655ZM88.8555 9.07C87.4555 9.07 86.2955 9.47 85.3755 10.27C84.4555 11.05 83.8855 12.1 83.6655 13.42H93.6855C93.4655 12 92.9055 10.92 92.0055 10.18C91.1055 9.44 90.0555 9.07 88.8555 9.07Z" fill={fill}/>
      <path d="M106.796 6.61C108.556 6.61 109.966 7.17 111.026 8.29C112.086 9.39 112.616 10.89 112.616 12.79V22H110.006V12.94C110.006 11.72 109.676 10.78 109.016 10.12C108.356 9.44 107.446 9.1 106.286 9.1C104.986 9.1 103.936 9.51 103.136 10.33C102.336 11.13 101.936 12.37 101.936 14.05V22H99.3257V7H101.936V9.16C102.996 7.46 104.616 6.61 106.796 6.61Z" fill={fill}/>
      <path d="M124.419 9.52H120.429V17.65C120.429 18.39 120.569 18.92 120.849 19.24C121.149 19.54 121.599 19.7 122.199 19.72C122.799 19.72 123.539 19.7 124.419 19.66V22C122.139 22.3 120.469 22.12 119.409 21.46C118.349 20.78 117.819 19.51 117.819 17.65V9.52H114.849V7H117.819V3.58L120.429 2.8V7H124.419V9.52Z" fill={fill}/>
      <path d="M137.947 7H140.557V22H137.947V19.84C136.887 21.54 135.267 22.39 133.087 22.39C131.327 22.39 129.917 21.84 128.857 20.74C127.797 19.62 127.267 18.11 127.267 16.21V7H129.877V16.06C129.877 17.28 130.207 18.23 130.867 18.91C131.527 19.57 132.437 19.9 133.597 19.9C134.897 19.9 135.947 19.5 136.747 18.7C137.547 17.88 137.947 16.63 137.947 14.95V7Z" fill={fill}/>
      <path d="M147.141 9.52C148.001 7.66 149.561 6.73 151.821 6.73V9.46C150.541 9.4 149.441 9.74 148.521 10.48C147.601 11.22 147.141 12.41 147.141 14.05V22H144.531V7H147.141V9.52Z" fill={fill}/>
      <path d="M155.384 15.7C155.644 17.04 156.254 18.08 157.214 18.82C158.194 19.56 159.394 19.93 160.814 19.93C162.794 19.93 164.234 19.2 165.134 17.74L167.354 19C165.894 21.26 163.694 22.39 160.754 22.39C158.374 22.39 156.434 21.65 154.934 20.17C153.454 18.67 152.714 16.78 152.714 14.5C152.714 12.24 153.444 10.36 154.904 8.86C156.364 7.36 158.254 6.61 160.574 6.61C162.774 6.61 164.564 7.39 165.944 8.95C167.344 10.49 168.044 12.35 168.044 14.53C168.044 14.91 168.014 15.3 167.954 15.7H155.384ZM160.574 9.07C159.174 9.07 158.014 9.47 157.094 10.27C156.174 11.05 155.604 12.1 155.384 13.42H165.404C165.184 12 164.624 10.92 163.724 10.18C162.824 9.44 161.774 9.07 160.574 9.07Z" fill={fill}/>
      <path d="M172.709 11.05C172.709 11.63 172.999 12.1 173.579 12.46C174.159 12.8 174.859 13.08 175.679 13.3C176.499 13.5 177.319 13.74 178.139 14.02C178.959 14.28 179.659 14.74 180.239 15.4C180.819 16.04 181.109 16.87 181.109 17.89C181.109 19.25 180.579 20.34 179.519 21.16C178.479 21.98 177.139 22.39 175.499 22.39C174.039 22.39 172.789 22.07 171.749 21.43C170.709 20.79 169.969 19.94 169.529 18.88L171.779 17.59C172.019 18.31 172.469 18.88 173.129 19.3C173.789 19.72 174.579 19.93 175.499 19.93C176.359 19.93 177.069 19.77 177.629 19.45C178.189 19.11 178.469 18.59 178.469 17.89C178.469 17.31 178.179 16.85 177.599 16.51C177.019 16.15 176.319 15.87 175.499 15.67C174.679 15.45 173.859 15.2 173.039 14.92C172.219 14.64 171.519 14.18 170.939 13.54C170.359 12.9 170.069 12.08 170.069 11.08C170.069 9.78 170.569 8.71 171.569 7.87C172.589 7.03 173.859 6.61 175.379 6.61C176.599 6.61 177.679 6.89 178.619 7.45C179.579 7.99 180.299 8.75 180.779 9.73L178.589 10.96C178.049 9.68 176.979 9.04 175.379 9.04C174.639 9.04 174.009 9.22 173.489 9.58C172.969 9.92 172.709 10.41 172.709 11.05Z" fill={fill}/>
    </svg>
   );
}
 
export default Logo;