import React from "react";
import { Link as ReachLink } from "gatsby";
import { Link, Flex, Box, Container } from "@chakra-ui/react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import PageContainer from "./PageContainer";
import Logo from "./Logo";

const Navigation = ({ location }) => {
  return (
    <Box pos="absolute" w="full" left="0" top={[5, 5, 12]}>
      <PageContainer>
        <Container maxW="full" centerContent>
          <Flex justifyContent="space-between" w="full" maxW="6xl">
            <Link
              as={ReachLink}
              to="/"
              w={[28, 32, 44, 48]}
              d="flex"
              alignItems="center"
            >
              <Logo fill={location.pathname === "/" ? "#fff" : "#06003C"} />
            </Link>
            <Flex>
              <Link
                as={ReachLink}
                to="/"
                textColor={
                  location.pathname === "/" ? "brand.700" : "brand.100"
                }
                _activeLink={{ fontWeight: "700" }}
                fontSize={["xs", "sm", "lg"]}
              >
                Home
              </Link>
              <Link
                as={ReachLink}
                to="/consulting/"
                textColor={
                  location.pathname === "/" ? "brand.700" : "brand.100"
                }
                _activeLink={{ fontWeight: "700" }}
                ml={[4, 10, 10]}
                fontSize={["xs", "sm", "lg"]}
              >
                Consulting
              </Link>
              {location.pathname === "/" ||
              location.pathname === "/consulting/" ? (
                <Link
                  as={AnchorLink}
                  href="#contact"
                  textColor={
                    location.pathname === "/" ? "brand.700" : "brand.100"
                  }
                  _activeLink={{ fontWeight: "700" }}
                  ml={[4, 10, 10]}
                  fontSize={["xs", "sm", "lg"]}
                >
                  Contact
                </Link>
              ) : (
                <Link
                  as={ReachLink}
                  to="/?contact"
                  textColor={
                    location.pathname === "/" ? "brand.700" : "brand.100"
                  }
                  _activeLink={{ fontWeight: "700" }}
                  ml={[4, 10, 10]}
                  fontSize={["xs", "sm", "lg"]}
                >
                  Contact
                </Link>
              )}
            </Flex>
          </Flex>
        </Container>
      </PageContainer>
    </Box>
  );
};

export default Navigation;
