import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      100: "#06003C",
      200: "#44406D",
      300: "#979BA7",
      400: "#F32D5F",
      450: "#FF517D",
      500: "#579BB0",
      600: "#F7FAFD",
      700: "#FFFFFF",
    },
    fonts: {
      body: "Montserrat, sans-serif",
      heading: "Montserrat, sans-serif",
      mono: "Menlo, monospace",
    },
    fontSizes: {
      xs: "0.75rem",
      sm: "0.875rem",
      md: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "2rem",
      "4xl": "2.5rem",
      "5xl": "3.4375rem",
    },
    lineHeights: {
      normal: "normal",
      none: "1",
      shorter: "1.1",
      short: "1.25",
      base: "1.375",
      tall: "1.5",
      taller: "1.625",
    },
  },
});
